import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import Div100vh from "react-div-100vh";
import { graphql } from "gatsby";
import { AppContext } from "~context/AppContext";
import AlphaVideo from "~components/AlphaVideo";
import Layout from "~components/Layout";
import RotatingButtonLink from "~components/RotatingButtonLink";
import SEO from "~components/SEO";
import { useTimeout } from "~utils/hooks";

class IndexPageComponent extends Component {
  state = {
    mounted: false
  };

  componentDidMount() {
    const { appContext } = this.props;

    appContext.setActivePath(`/`);
    appContext.setShareUrl(`https://www.myfoodie.id`);

    setTimeout(() => {
      this.setState({
        mounted: true
      });
    }, 500);
  }

  //

  render() {
    const { frontmatter } = this.props;
    const { mounted } = this.state;

    //

    return (
      <Div100vh className="relative">
        <SEO
          frontmatterTitle={frontmatter.title}
          frontmatterDescription={frontmatter.seoDescription}
          frontmatterKeywords={frontmatter.seoKeywords}
        />

        <Layout
          className="index-page w-full h-full relative text-white"
          outerClassName="w-full h-full relative"
          style={{
            background: `linear-gradient(180deg, #FFD81A 0%, #FF900E 100%)`
          }}
          footerClass="white"
          footerType="desktop-only"
          headerClass="white"
        >
          <section className="w-screen h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center sm:pb-12">
            <div className="w-1/2 sm:w-3/4 xs:w-full relative">
              <AlphaVideo src="/uploads/alpha-video-1.mp4" />
            </div>
          </section>

          <section className="w-screen h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center sm:pb-16">
            <h1
              className="index-page__title w-full animation-appear sm:px-4 f1 text-center"
              style={{ animationDelay: `200ms` }}
            >
              What type of foodie are you?
            </h1>
          </section>

          {mounted && (
            <section className="index-page__button-container h-full relative flex flex-col justify-end">
              <div
                className="animation-appear flex items-end justify-end sm:items-center sm:justify-center pb-24 pr-16 sm:p-0 sm:pb-8"
                style={{ animationDelay: `300ms` }}
              >
                <RotatingButtonLink
                  className="infinispin relative"
                  path="/start"
                  text="Start the Quiz"
                />
              </div>
            </section>
          )}
        </Layout>
      </Div100vh>
    );
  }
}

IndexPageComponent.propTypes = {
  appContext: PropTypes.shape({
    setActivePath: PropTypes.func,
    setShareUrl: PropTypes.func
  }).isRequired,
  frontmatter: PropTypes.shape({
    title: PropTypes.string,
    rainingImages: PropTypes.arrayOf(PropTypes.shape({})),
    seoDescription: PropTypes.string,
    seoKeywords: PropTypes.string
  }).isRequired
};

//

const IndexPage = ({ data }) => {
  const appContext = useContext(AppContext);
  const { frontmatter } = data.markdownRemark;

  return (
    <IndexPageComponent appContext={appContext} frontmatter={frontmatter} />
  );
};

export default IndexPage;

IndexPage.defaultProps = {
  data: {
    markdownRemark: {
      frontmatter: {}
    }
  }
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({})
    })
  })
};

//

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        rainingImages {
          image {
            childImageSharp {
              fluid(maxWidth: 256) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageAlt
        }
        seoDescription
        seoKeywords
      }
    }
  }
`;
